import GigapixelViewer from "../../GigapixelViewer";
import { ReactComponent as Plus } from "../../../icons/plus.svg";
import { ReactComponent as Minus } from "../../../icons/minus.svg";

interface GigapixelModalProps {
  content: GigapixelModal;
}

const GigapixelModal = ({ content: { src, text } }: GigapixelModalProps) => {
  return (
    <div className="flex grow flex-col justify-center gap-4 overflow-auto pt-4">
      <GigapixelViewer tileSources={src} />
      {text && (
        <div className="flex basis-32 flex-row justify-end gap-2 overflow-auto">
          <div className="modal-text overflow-y-auto text-white">{text}</div>
        </div>
      )}
    </div>
  );
};

export default GigapixelModal;
