import { Color } from "@react-three/fiber";

const defaultColor: Color = "#0391BA";

interface CubeProps {
  color?: Color;
  onClick?: () => void;
}

const Cube = ({ color = defaultColor, onClick = () => null }: CubeProps) => (
  <mesh onClick={() => onClick()}>
    <boxBufferGeometry args={[1, 1, 1]} />
    <meshStandardMaterial color={color} />
  </mesh>
);

export default Cube;
