import Cube from "./Cube";
import Debug from "./Debug";
import PinpointControl from "./PinpointControl";

const DevTools = {
  Cube,
  Debug,
  PinpointControl,
};

export default DevTools;
