import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { useControls } from "leva";
import { useEffect, useState } from "react";
import Model from "./Model";
import { isVisible } from "@testing-library/user-event/dist/utils";

interface SceneProps {
  model: Model;
}

const BackGround = ({ model }: SceneProps) => {
  const sfondo = useGLTF("/models/sfondo_studio.glb");

  const [selectedColor, setSelectedColor] = useState('')
 
    const Background  = useControls({
        background: {
          value: model.background ,
        },
      });
      
      useEffect(() => {
        if (!model.edit) {
          setSelectedColor(model.background);
        } else if (model.edit) {
          setSelectedColor(Background.background);
        }
      }, [ model.edit,Background.background]);
  
  

  return (
    <>
       <primitive object={sfondo.scene.children[0]} scale={5} position-y={-0.2}>
      <meshStandardMaterial color={selectedColor} />
      </primitive>
       
    </>
  );
};

export default BackGround;

