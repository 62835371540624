const apiURL = process.env.REACT_APP_API_URL;

class DLResource implements DLResponse {
  cdnToken: string;
  entlToken: string;
  licenseURL: string;
  sessionId: string;
  timeToRefresh: string;
  url: URL;

  type: string;

  constructor(
    {
      cdnToken,
      entlToken,
      licenseURL,
      sessionId,
      timeToRefresh,
      url,
    }: DLResponse,
    type: string,
  ) {
    this.cdnToken = cdnToken;
    this.entlToken = entlToken;
    this.licenseURL = licenseURL;
    this.sessionId = sessionId;
    this.timeToRefresh = timeToRefresh;
    this.url = new URL(url);
    this.type = type;
  }

  buildURL(path: string) {
    return `${this.url.origin}${this.url.pathname}/${path}${this.url.search}`;
  }

  static get(uuid: string) {
    return new Promise<DLResource>(async (resolve, reject) => {
      const entitlement = await fetch(
        `${apiURL}/service-delivery/servicedelivery/entitlement/entita/${uuid}`,
      ).then((res) => res.json().then((data) => data));
      const rendition = {
        type: entitlement.type,
        subType: "HD",
        platform: "Chrome",
        appContext: null,
      };
      switch (entitlement.type) {
        case "BRANO":
        case "IMMAGINE":
        case "VIDEO":
          // DO NOTHING
          break;
        case "GPX":
          rendition.type = "iiiF Manifest";
          rendition.subType = "ORIG";
          break;
        case "GPX360":
          if (entitlement.renditions.length > 0) {
            // Probabilmente ritonerà gpxVRGoogle andra convertito in gigapx360
            rendition.type = entitlement.renditions[0].type;
            rendition.subType = entitlement.renditions[0].subType;
          }
          break;
        case "FOTO360":
          if (entitlement.renditions.length > 0) {
            // Probabilmente ritornera l'url dell'end point
            rendition.type = entitlement.renditions[0].type;
            rendition.subType = entitlement.renditions[0].subType;
          }
          break;
        case "GLBTM":
          // Probabilmente ritornera l'url dell'end point
          rendition.appContext = entitlement.type;
          break;
        case "GLB":
          // Probabilmente ritornera l'url dell'end point
          rendition.appContext = entitlement.type;
          break;
        case "VT":
          // Probabilmente ritornera il json
          rendition.appContext = entitlement.type;
          break;
      }
      let headers = {};
      if (rendition.appContext) {
        headers = {
          "X-APP-CONTEXT": rendition.appContext,
        };
      }
      const playbackURL = `${apiURL}/play/playback/url/${uuid}/${rendition.platform}/${rendition.type}/${rendition.subType}`;
      resolve(
        new DLResource(
          (await (await fetch(playbackURL, { headers })).json()) as DLResponse,
          entitlement.type,
        ),
      );
    });
  }
}

export default DLResource;
