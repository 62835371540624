import { useEffect, useState } from "react";
import useLoaderStore from "./store";
import { ReactComponent as LoaderIcon } from "../../icons/logo_animated.svg";

const LoadingOverlay = () => {
  const loading = useLoaderStore((state) => state.loading);
  const [first, setFirst] = useState(true);

  useEffect(() => {
    if (loading > 0 && first) {
      setFirst(false);
    }
  }, [loading]);

  if (!first && loading == 0) {
    return null;
  }

  return (
    <div className="fixed top-0 z-[1001] flex h-full w-full items-center justify-center bg-black/50">
      <h2 className="text-2xl text-white">Loading...</h2>
    </div>
  );
};

export default LoadingOverlay;
