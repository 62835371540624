import { ReactNode } from "react";

interface DebugProps {
  children?: ReactNode;
}

const Debug = ({ children }: DebugProps) => {
  if (!process.env.REACT_APP_DEBUG) {
    return null;
  }
  return <>{children}</>;
};

export default Debug;
