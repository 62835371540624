import { Environment, useGLTF } from "@react-three/drei";
import { useEffect } from "react";
import * as THREE from "three";
import { GLTF } from "three-stdlib";
import useModelStore from "../../store";
import BackGround from "./BackGround";
import Group from "./Group";
// Decided convention of internal structure of the models
type ModelStructure = GLTF & {
  Scene: THREE.Group;
  nodes: { Scene: THREE.Group };
};
interface BuildingProps {
  model: Model;
}
const Model = ({ model }: BuildingProps) => {
  const setDiagonal = useModelStore((state) => state.setDiagonal);
  const setUuid = useModelStore((state) => state.setUuid);
  const { scene: rootScene } = useGLTF(model.src) as ModelStructure;
  useEffect(() => {
    const boundingBox = new THREE.Box3();
    boundingBox.expandByObject(rootScene);
    setDiagonal(boundingBox.max.distanceTo(boundingBox.min));
    setUuid(rootScene.uuid);
  }, [rootScene]);
  return (
    <group dispose={null}>
      <BackGround model={model} />
      <group>
        <Environment preset={model.preset as "city"} />
        {rootScene.children[0] instanceof Object
          ? rootScene.children.map((child) => (
              <Group key={child.name} node={child} />
            ))
          : null}
        {rootScene.children[0] instanceof THREE.Mesh ? (
          <primitive object={rootScene} />
        ) : null}
      </group>
    </group>
  );
};
export default Model;