import { useQuery } from "react-query";
import DLResource from "../utils/DLResource";

const useModelQuery = (uuid: string) => {
  return useQuery<Model>("model", async () => {
    if (uuid.startsWith("local-")) {
      return (await (
        await fetch(`/json/model/${uuid.slice(6)}.json`)
      ).json()) as Model;
    }
    const dlResource = await DLResource.get(uuid);
    const model = (await (
      await fetch(dlResource.buildURL("data.json"))
    ).json()) as Model;
    model.src = dlResource.buildURL(model.src);
    return model;
  });
};

export default useModelQuery;
