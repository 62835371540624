import { useEffect, useRef } from "react";
import useHotspotStore from "./Hotspots/store";
import * as _THREE from "three";

interface Props {
  look_at?: Vector3Tuple;
  move_to?: Vector3Tuple;
  delay?: number;
}

const CameraAnimation = ({
  look_at = [0, 0, 0],
  move_to = [0, 0, 0],
  delay = 0,
}: Props) => {
  const setCamera = useHotspotStore((state) => state.setCamera);
  const timeoutID = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (timeoutID.current) {
      clearTimeout(timeoutID.current);
    }
    const id = setTimeout(() => setCamera(look_at, move_to), delay);
    if (id) {
      timeoutID.current = id;
    }
  }, [look_at, move_to]);

  return null;
};
export default CameraAnimation;
