import HotspotIcon from "../../icons/hotspot";

interface ResourceLinkProps {
  link: ResourceLink;
}

const ResourceLink = ({ link: { href, icon, label } }: ResourceLinkProps) => {
  return (
    <a
      rel="ar"
      className="group item-link pointer-events-auto my-2 flex flex-row items-center justify-start font-['Roboto'] text-xl font-semibold text-white decoration-1 hover:text-period-color hover:underline"
      href={href}
    >
      <HotspotIcon icon={icon} className="w-9" />
      <span className="pl-2 font-[Roboto] text-lg font-[500] leading-6 drop-shadow-md">
        {label}
      </span>
    </a>
  );
};

export default ResourceLink;
